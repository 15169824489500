import React, { useEffect } from 'react';
import { init, loadRemote, registerRemotes } from '@module-federation/enhanced/runtime';
import type { RemoteEntryType } from '@module-federation/sdk';
interface MfeComponentProps {
  /** The URL where the MFE is hosted */
  url: string;
  /** This corresponds to a **key** in the MFE's build configuration `exposes` object. */
  module: string;
  /** This corresponds to the `name` field in the MFE's build configuration */
  remote: string;
  /** What type of module is being exported. The most common options are `'module'` for modern ES module based builds or `'window'` for older builds. */
  moduleType: RemoteEntryType;
  /** Any data provided here will be passed along to the `psMfeInit` function */
  initializationData?: Record<string, any>;
}

/**
 * This is the standardized interface for a PowerSchool MFE to be loaded via module federation.
 * See: https://gitlab.com/powerschoolgroup/ui-ux/mfe-architecture/mfe-utils/-/blob/develop/src/interfaces/mfe-init-function.ts?ref_type=heads
 */
interface PowerSchoolMfeModule {
  default: {
    psMfeInit: (props: {
      element: HTMLElement;
      [key: string]: any;
    }) => void | Promise<{ unloadMfe: () => void }>;
  };
}

// We initialize FCUI's module federation runtime once. We will add to it at runtime as we load remotes.
const federationHost = init({
  name: 'fcui',
  remotes: [],
});

type MountStatus = 'unmounted' | 'pending' | 'mounted';

export const MfeComponent = (props: MfeComponentProps) => {
  const mfeHostRef = React.useRef<HTMLDivElement>(null);
  // We track the status via a ref to ensure we don't re-initialize the MFE when the useEffect runs again
  const mountStatusRef = React.useRef<MountStatus>('unmounted');

  useEffect(() => {
    if (mountStatusRef.current !== 'unmounted') {
      return;
    }

    mountStatusRef.current = 'pending';

    let unloadMfe: () => void | undefined;

    // Ensure we only register the remote once
    if (!federationHost.moduleCache.has(props.remote)) {
      registerRemotes([
        {
          name: props.remote,
          entry: props.url,
          type: props.moduleType,
        },
      ]);
    }

    // The id takes the remote name (e.g. ps_mfe_naviance_profiles) and the module name (e.g. ./ProfilesMfe)
    // and combines them into a syntactically valid path for the module federation runtime
    // For example: ps_mfe_naviance_profiles/ProfilesMfe
    const id = props.remote + props.module.replace(/^\./, '');

    loadRemote<PowerSchoolMfeModule>(id)
      .then((importedModule) => {
        if (typeof importedModule?.default?.psMfeInit !== 'function') {
          throw new Error('Module does not have a psMfeInit function');
        }

        const initReturn = importedModule.default.psMfeInit({
          element: mfeHostRef.current,
          ...props.initializationData,
        });

        // If init function returns a promise, wait for it to resolve
        // and grab it's unload function so we can clean up later
        if (initReturn && 'then' in initReturn) {
          initReturn.then(({ unloadMfe: unload }) => {
            unloadMfe = unload;
          });
        }
        mountStatusRef.current = 'mounted';
      })
      .catch((err) => {
        mountStatusRef.current = 'unmounted';
        throw err;
      });

    return () => {
      unloadMfe?.();
    };
  }, []);

  return <div ref={mfeHostRef} />;
};
