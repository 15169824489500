import { NeonButton } from '@ps-refarch-ux/neon';
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getConsideringAllEntities,
  getApplyingAllEntitiesAsArray,
} from 'selectors/colleges';
import {
  saveCollegesIamThinking,
  saveCollegesIamThinkingByHobsonsIds,
} from 'modules/colleges/considering';
import {
  getAllAMConnectibleColleges,
  getAmFilteredConnectedMatchScids,
} from 'selectors/colleges/activeMatchConnectionMatching';
import { getUnfilteredMatchingColleges } from 'selectors/colleges/lookingForStudentsLikeYou';
import { fetchUnfilteredMatchingColleges } from 'modules/colleges/lookingForStudentsLikeYou';
import { fetchCollegeUuid } from 'modules/colleges/hubs';
import { hasEnhancedProfile } from 'modules/colleges/profiles';
import {
  collegeProfilesPermissions,
  collegeFavoritingPermissions,
  collegeConnectionPermissions,
} from 'selectors/permissions';
import { getFeatureFlags } from 'selectors/featureFlags';
import type { LegacyMatch, AMMatch } from 'types/colleges';
import {
  CollegePlanningAction,
  POST_FAV_ACTION_MESSAGE,
  EVENT_TYPE,
  EVENT_APPLICATION_NAME,
} from 'constants/pbChatBotMfeDsApi';
import { getBlacklistedColleges } from 'selectors/colleges/lookingForStudentsLikeYou';
import { getCurrentUser } from 'selectors/auth';
import { trackActiveMatch } from 'modules/tracking';

import defaultCollegeImage from './defaultCollegeImage.svg';
import styles from './CollegeCard.scss';
import { fetchAllStudentFavoritedDataForPB } from 'modules/powerbuddy/favorites';
import { EmbeddedYouVisit } from './videoTours';

export interface CollegeData {
  name: string;
  url: string;
  imageUrl?: string;
  scid?: number;
  navianceId?: string;
  ceebCode?: string;
  ipedsCode?: string;
}

export interface CollegeCardProps {
  college: CollegeData;
  cardClass: string;
  sendPostActionMessage: (
    message: string,
    actionName: string,
    actionDetails: Record<string, unknown>
  ) => void;
}

interface CollegePlanningActionDetails {
  college_name: string;
  scid?: number;
  navianceId?: string;
}

// This interface is not exhaustive, but it is sufficient for the purposes of this component
interface NavDbCollegeData {
  id: string;
  uuid: string;
}

// Validates that the URL is either an HTTP/HTTPS URL or a data URL with an image MIME type
// See https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/Data_URLs
const validImageUrlRegex = /^https?:\/\/|^data:image\/\w+;base64,/;

export function CollegeCard({
  college,
  cardClass,
  sendPostActionMessage,
}: CollegeCardProps): React.ReactElement {
  const uniqueId = useId();

  const [collegeNavDbData, setCollegeNavDbData] = useState<NavDbCollegeData>(null);
  const [connectionOfferEvaluated, setConnectionOfferEvaluated] = useState(false);

  const mayViewCollegeProfiles = useSelector(collegeProfilesPermissions);
  const showCollegeCard = Boolean(collegeNavDbData?.uuid);
  const currentUser = useSelector(getCurrentUser);
  const featureFlags = useSelector(getFeatureFlags) as Record<string, boolean>;
  const pbFavoritingEnabled =
    featureFlags.releaseNavianceStudentPowerBuddyFavoriteCollege;
  const pbConnectingEnabled =
    featureFlags.releaseNavianceStudentPowerBuddyEnableCollegeConnections;
  const collegesApplyingCheckEnabled =
    featureFlags.featureNavianceStudentPowerBuddyEnableCollegesApplyingCheck;
  const embeddedYouVisitEnabled =
    featureFlags.featureNavianceStudentPowerBuddyVirtualTour;
  const isFavorited = useIsFavorited(college.scid, collegesApplyingCheckEnabled);

  const mayAddColleges = useSelector(collegeFavoritingPermissions);
  // collegeConnectionPermissions is responsible for compliance that users under 13 years old cannot connect with colleges
  const mayConnectWithColleges = useSelector(collegeConnectionPermissions);

  const blacklistedColleges: number[] = useSelector(getBlacklistedColleges);
  const amFilteredConnectedMatchScids: string[] = useSelector(
    getAmFilteredConnectedMatchScids
  );
  const isCollegeConnected: boolean = amFilteredConnectedMatchScids.includes(
    college.scid.toString()
  );

  const dispatch = useDispatch();
  const [isInProgress, setIsInProgress] = useState(false);
  const [showEmbeddedYouVisit, setShowEmbeddedYouVisit] = useState(false);

  const isValidImageUrl = validImageUrlRegex.test(college.imageUrl);

  useEffect(() => {
    dispatch(fetchUnfilteredMatchingColleges());

    dispatch(fetchCollegeUuid(college.scid)).then((collegeData) => {
      if (collegeData) {
        setCollegeNavDbData(collegeData);
      }
    });
    if (embeddedYouVisitEnabled) {
      dispatch(hasEnhancedProfile([college.scid])).then((result) => {
        if (result.enhanced.length > 0) {
          setShowEmbeddedYouVisit(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (
      isFavorited &&
      mayStudentConnectWithThisCollege &&
      !isCollegeConnected &&
      !connectionOfferEvaluated
    ) {
      const payload = {
        college_name: college.name,
        scid: college.scid,
        navianceId: college.navianceId,
      };
      sendPostActionMessage(
        POST_FAV_ACTION_MESSAGE,
        CollegePlanningAction.ADDED_TO_LIST_OFFER_CONNECTION,
        payload
      );
      setConnectionOfferEvaluated(true);
    }
  }, []);

  const connectibleLegacyColleges: LegacyMatch[] = useSelector(
    getUnfilteredMatchingColleges
  );
  const connectibleActiveMatchColleges: AMMatch[] = useSelector(
    getAllAMConnectibleColleges
  );

  // Auxiliary function to determine if a college is connectible
  const mayStudentConnectWithThisCollege = () => {
    return (
      pbConnectingEnabled &&
      mayConnectWithColleges &&
      isConnectibleCollege(
        college,
        connectibleLegacyColleges,
        connectibleActiveMatchColleges
      ) &&
      !blacklistedCollege(college.scid, blacklistedColleges)
    );
  };

  // Yes, the error handling here looks weird. That's because due to the way our Redux middleware
  // works, the error handling is done in the `onError` callback, and the `.then` is executed
  // **after** the `onError` callback. As a result we have to put a guard flag in place to
  // prevent the success case from executing if an error has occurred.
  // See the comment on `saveCollegesIAmThinkingInternal` for more details.
  const handleFavoriteClickAndConnectionOffer = () => {
    let errorOcurred = false;

    setIsInProgress(true);
    setConnectionOfferEvaluated(true);

    const AddToListCallback = async () => {
      if (errorOcurred) {
        return;
      }

      const messageType = mayStudentConnectWithThisCollege()
        ? CollegePlanningAction.ADDED_TO_LIST_OFFER_CONNECTION
        : CollegePlanningAction.ADDED_TO_LIST_NO_CONNECTION;

      // Simplified payload assignment
      const payload = {
        college_name: college.name,
        scid: college.scid,
        navianceId: college.navianceId,
      };

      // Post the action message
      sendPostActionMessage(POST_FAV_ACTION_MESSAGE, messageType, payload);
    };

    const onError = (e: Error) => {
      errorOcurred = true;
      sendPostActionMessage(
        'something went wrong',
        CollegePlanningAction.REQUEST_ACTION_ERROR,
        {}
      );
    };

    // We use the id from the naviance db because we have observed cases where the navianceID
    // from DS API/elastic search has been incorrect or missing.
    const saveCollegeAction = collegeNavDbData.id
      ? saveCollegesIamThinking([collegeNavDbData.id], onError)
      : saveCollegesIamThinkingByHobsonsIds([college.scid]);

    dispatch(saveCollegeAction)
      .then(AddToListCallback)
      .then(() => {
        fetchAllStudentFavoritedDataForPB()(dispatch);
        pbTrackEvent(dispatch, EVENT_TYPE.AM_FAVORITED, {
          connectibleActiveMatchColleges,
          currentUser,
          college,
        });
      })
      .finally(() => setIsInProgress(false));
  };

  const pbVirtualId = `pb-virtualtour-${uniqueId}-visit`;
  const imageUrl = isValidImageUrl ? college.imageUrl : defaultCollegeImage;

  return (
    <div>
      {showCollegeCard && (
        <div className={`${cardClass} ${styles.collegeCard}`} data-testid="college-card">
          <p className={styles.collegeCardTitle}>{college.name}</p>
          <div className={styles.collegeCardImage}>
            {embeddedYouVisitEnabled && showEmbeddedYouVisit ? (
              <div className={pbVirtualId}>
                <EmbeddedYouVisit
                  ipedscode={college.ipedsCode}
                  imageURL={imageUrl}
                  elementId={pbVirtualId}
                />
              </div>
            ) : (
              <img
                className={styles.collegeCardImage}
                src={imageUrl}
                // Because the image is purely decorative and we don't know the content in advance,
                // we should use an empty alt attribute.
                // See: https://www.w3.org/WAI/tutorials/images/decorative/
                alt=""
              />
            )}
          </div>
          <div className={styles.collegeCardButtons}>
            {mayViewCollegeProfiles && (
              <NeonButton
                id={`pb-college-card-${uniqueId}-visit-website-btn`}
                dataType="borderless"
                dataText="Visit profile"
                dataIcon="open-in-new"
                data-testid="visit-website-btn"
                onClick={(): void => {
                  window.open(
                    `/colleges/profile/${collegeNavDbData.uuid}/overview`,
                    '_blank'
                  );
                }}
              />
            )}
            {pbFavoritingEnabled && mayAddColleges && (
              <NeonButton
                id={`pb-college-card-${uniqueId}-add-to-list-btn`}
                dataType="primary"
                // Ideally, we would use dataIsLoading for when in progress
                // but we are stuck on an old version of Neon for the moment
                disabled={isFavorited || isInProgress}
                dataText="Add to list"
                dataAriaLabel="add college to favorite colleges list"
                dataIcon="add"
                data-testid="favorite-college-btn"
                onClick={handleFavoriteClickAndConnectionOffer}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

let idCounter = 0;

// This is a simple backport of the useId hook from React 18
// It is not suitable for all the same use cases as the React 18 version
// but meets the needs of this component
export function useId() {
  const [id] = useState(++idCounter);
  return id;
}

function useIsFavorited(scid: number, collegesApplyingCheckEnabled: boolean): boolean {
  const allFavorited = useSelector(getConsideringAllEntities);
  const allApplying = useSelector(getApplyingAllEntitiesAsArray);

  const isFavorited = useMemo(() => {
    const favoritedColleges = Object.values(allFavorited);
    const applyingColleges = Object.values(allApplying);

    return (
      favoritedColleges.some((college) => college.hobsonsId === scid) ||
      (collegesApplyingCheckEnabled &&
        applyingColleges.some((college) => college.hobsonsId === scid))
    );
  }, [allFavorited, allApplying, scid, collegesApplyingCheckEnabled]);

  return isFavorited;
}

function isConnectibleCollege(
  displayedCollege: CollegeData,
  connectibleLegacyColleges: LegacyMatch[],
  connectibleActiveMatchColleges: AMMatch[]
): boolean {
  // These results may not be correct in all cases, depending on
  // how robust the data from PowerBuddy is. This is a best effort
  // attempt to match against the connectible college's data.
  const connectibleLegacyCollege = connectibleLegacyColleges.some(
    (college) =>
      college.hobsonsId === displayedCollege.scid ||
      college.name === displayedCollege.name
  );
  const connectibleActiveMatchCollege = connectibleActiveMatchColleges.some(
    (college) =>
      college.scid === displayedCollege.scid.toString() ||
      college.name === displayedCollege.name
  );
  return connectibleLegacyCollege || connectibleActiveMatchCollege;
}

function isCollegeConnected(
  displayedCollege: CollegeData,
  connectedActiveMatchColleges: AMMatch[]
): boolean {
  // These results may not be correct in all cases, depending on
  // how robust the data from PowerBuddy is. This is a best effort
  // attempt to match against the connectible college's data.
  return connectedActiveMatchColleges.some(
    (college) =>
      college.scid === displayedCollege.scid.toString() ||
      college.name === displayedCollege.name
  );
}

function blacklistedCollege(
  displayedCollegeScid: number,
  blacklistedCollegeScids: number[]
): boolean {
  return blacklistedCollegeScids.includes(displayedCollegeScid);
}

export function pbTrackEvent(
  dispatch: any,
  eventName: string,
  { connectibleActiveMatchColleges, currentUser = {}, college }: Record<string, any>
) {
  const collegeSubscription = findSelectedCollege(
    connectibleActiveMatchColleges,
    college
  );

  // call this function only collge scubscription/Match college is available
  if (collegeSubscription && collegeSubscription.subscriptions) {
    const { scid, subscriptions } = collegeSubscription || {};
    const { highSchoolId = '', classYear = '', gpa = 0 } = currentUser;

    dispatch(
      trackActiveMatch([
        {
          application: EVENT_APPLICATION_NAME,
          location: EVENT_APPLICATION_NAME,
          type: eventName,
          classification: 'AA',
          gpa,
          sat: 0,
          act: 0,
          classYear: classYear.toString(),
          highSchoolId,
          ceeb: college.ceebCode || '',
          scid,
          subscriptions,
        },
      ])
    );
  }
}

function findSelectedCollege(connectibleActiveMatchColleges, college) {
  // Find college and subscription details in connectibleActiveMatchColleges
  const scid = college.scid || college.actionDetails.scid;
  const collegeName = college.name || college.actionDetails.college_name;
  return connectibleActiveMatchColleges.find(
    (collegeSubscription) =>
      collegeSubscription.scid === scid.toString() ||
      collegeSubscription.name === collegeName
  );
}
