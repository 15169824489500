// @flow

import api from 'config/api';
import constantsGenerator from 'utils/constantsGenerator';
import { shouldUpdateCount } from 'selectors/mail';
import normalizeItems from 'utils/normalizeItems';
import collectIds from 'utils/collectIds';
import moment from 'moment';
import type { Message } from 'types/mail';
import { dateSort } from 'utils/sorters';
import { parseValidationErrors } from 'utils/validations';
import { injectAsyncReducers } from 'store';
import { getFeatureFlags } from 'selectors/featureFlags';
import { isFeatureFlagAllowed } from 'utils/permissions';

const sortMessages = (messages) => [...messages].sort(dateSort('timeScheduled')).reverse();

const generateConstants = constantsGenerator('fc/resume');

const [GET_MESSAGES, GET_MESSAGES_SUCCESS, GET_MESSAGES_FAIL]: Array<string> = generateConstants(
  'fc/mail/GET_MESSAGES'
);
const [
  GET_MESSAGES_COUNT,
  GET_MESSAGES_COUNT_SUCCESS,
  GET_MESSAGES_COUNT_FAIL,
]: Array<string> = generateConstants('fc/mail/GET_MESSAGES_COUNT');

const [
  GET_MESSAGE_BY_ID,
  GET_MESSAGE_BY_ID_SUCCESS,
  GET_MESSAGE_BY_ID_FAIL,
]: Array<string> = generateConstants('fc/mail/GET_MESSAGE_BY_ID');
const [SEND_REPLY, SEND_REPLY_SUCCESS, SEND_REPLY_FAIL]: Array<string> = generateConstants(
  'fc/mail/SEND_REPLY'
);
const TOGGLE_MESSAGE_DETAILS = 'fc/mail/TOGGLE_MESSAGE_DETAILS';
const VIEW_MESSAGE = 'fc/mail/VIEW_MESSAGE';
const OPEN_MESSAGE_REPLY = 'fc/mail/OPEN_MESSAGE_REPLY';
const EDIT_MAIL_REPLY = 'fc/mail/EDIT_MAIL_REPLY';
const CANCEL_MAIL_REPLY = 'fc/mail/CANCEL_MAIL_REPLY';
const CLEAR_SUCCESS_NOTIFICATION = 'fc/mail/CLEAR_SUCCESS_NOTIFICATION';

function formatMessages(
  messages: Array<Message>,
  user: Object,
  username: String,
  featureFlags: Object
) {
  return messages.map((message) => {
    let formattedHtmlMessage = message.htmlMessage;
    let formattedMessage = message.message;
    const timeScheduled = moment(message.timeScheduled);
    const isRecipientDetailsToggleOn = isFeatureFlagAllowed(
      'releaseNavianceStudentEmailGetRecipientDetails',
      featureFlags
    );
    const replacements =
      isRecipientDetailsToggleOn && message?.recipient
        ? {
            '!firstname': message.recipient.firstName,
            '!lastname': message.recipient.lastName,
            '!nickname': message.recipient.nickname,
            '!fc_username': message.recipient.username,
            '!fc_regcode': message.recipient.accessCode,
          }
        : {
            '!firstname': user.firstName,
            '!lastname': user.lastName,
            '!nickname': user.nickname,
            '!fc_username': username,
            '!fc_regcode': user.accessCode,
          };

    Object.keys(replacements).forEach((key) => {
      formattedHtmlMessage = formattedHtmlMessage.replace(
        new RegExp(key, 'g'),
        replacements[key] || ''
      );
      formattedMessage = formattedMessage.replace(new RegExp(key, 'g'), replacements[key] || '');
    });

    return {
      ...message,
      timeScheduled: {
        long: timeScheduled.format('MMM D, YYYY h:mm A'),
        short: timeScheduled.format('MM/D'),
      },
      reply: {
        subject: '',
        text: '',
      },
      htmlMessage: formattedHtmlMessage,
      message: formattedMessage,
    };
  });
}

type State = {
  messages: {
    results: Array<number>,
    entities: { [number]: Message },
    count: {
      viewed: number,
      unread: number,
      unreadTs: number,
    },
    loaded: boolean,
    loading: boolean,
    validation: Object,
  },
};

const initialState = {
  messages: {
    results: [],
    entities: {},
    count: {
      viewed: 0,
      unread: 0,
      unreadTs: 0,
    },
    loaded: false,
    loading: false,
    validation: {},
  },
};

/**
 * Reducer
 */
export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case GET_MESSAGES:
      return {
        ...state,
        messages: {
          ...state.messages,
          loading: true,
        },
      };
    case GET_MESSAGES_SUCCESS: {
      const featureFlags = getFeatureFlags(state);
      const data = formatMessages(
        sortMessages(action.result.data),
        action.user,
        action.username,
        featureFlags
      );

      return {
        ...state,
        messages: {
          ...state.messages,
          entities: normalizeItems(data, 'id'),
          results: collectIds(data, 'id'),
          loaded: true,
          loading: false,
          unreadTs: Date.now(),
        },
      };
    }
    case GET_MESSAGES_COUNT_SUCCESS:
      return {
        ...state,
        messages: {
          ...state.messages,
          count: {
            viewed: action.result.viewed,
            unread: action.result.unread,
          },
        },
      };
    case GET_MESSAGES_FAIL:
      return {
        ...state,
        messages: {
          ...state.messages,
          loading: false,
        },
      };
    case VIEW_MESSAGE: {
      const message = state.messages.entities[action.id];
      const wasRead = message.viewed;
      const viewedCount = state.messages.count.viewed;
      const unreadCount = state.messages.count.unread;

      return {
        ...state,
        messages: {
          ...state.messages,
          count: {
            ...state.messages.count,
            viewed: wasRead ? viewedCount : viewedCount + 1,
            unread: wasRead ? unreadCount : unreadCount - 1,
          },
        },
      };
    }
    case TOGGLE_MESSAGE_DETAILS: {
      const message = state.messages.entities[action.id];

      return {
        ...state,
        messages: {
          ...state.messages,
          entities: {
            ...state.messages.entities,
            [action.id]: {
              ...message,
              viewed: true,
              detailsOpen: !message.detailsOpen,
            },
          },
        },
      };
    }
    case OPEN_MESSAGE_REPLY: {
      const message = state.messages.entities[action.id];

      return {
        ...state,
        messages: {
          ...state.messages,
          entities: {
            ...state.messages.entities,
            [action.id]: {
              ...message,
              replyOpen: true,
              reply: {
                text: '',
                subject: `Re: ${message.subject}`,
              },
            },
          },
        },
      };
    }
    case EDIT_MAIL_REPLY: {
      const message = state.messages.entities[action.id];

      return {
        ...state,
        messages: {
          ...state.messages,
          entities: {
            ...state.messages.entities,
            [action.id]: {
              ...message,
              reply: {
                ...message.reply,
                [action.field]: action.value,
              },
            },
          },
        },
      };
    }
    case CANCEL_MAIL_REPLY: {
      const message = state.messages.entities[action.id];

      return {
        ...state,
        messages: {
          ...state.messages,
          entities: {
            ...state.messages.entities,
            [action.id]: {
              ...message,
              replyOpen: false,
              reply: {
                text: '',
                subject: '',
              },
            },
          },
        },
      };
    }
    case SEND_REPLY_SUCCESS: {
      const message = state.messages.entities[action.id];

      return {
        ...state,
        messages: {
          ...state.messages,
          entities: {
            ...state.messages.entities,
            [action.id]: {
              ...message,
              replySuccessful: true,
              reply: {
                text: '',
                subject: '',
              },
            },
          },
          validation: {},
        },
      };
    }
    case CLEAR_SUCCESS_NOTIFICATION: {
      const message = state.messages.entities[action.id];

      return {
        ...state,
        messages: {
          ...state.messages,
          entities: {
            ...state.messages.entities,
            [action.id]: {
              ...message,
              replySuccessful: false,
            },
          },
        },
      };
    }
    case SEND_REPLY_FAIL:
      return {
        ...state,
        messages: {
          ...state.messages,
          validation: parseValidationErrors(action),
        },
      };
    default:
      return state;
  }
}

export function fetchMessagesCount() {
  return {
    types: [GET_MESSAGES_COUNT, GET_MESSAGES_COUNT_SUCCESS, GET_MESSAGES_COUNT_FAIL],
    promise: (client: Object) => client.get(`${api.host}/inbox/messages/count`),
  };
}

export function fetchUnreadCount() {
  return (dispatch: Function, getState: Function): Function | Promise<any> => {
    if (shouldUpdateCount(getState())) {
      return dispatch(fetchMessagesCount());
    }

    return Promise.resolve();
  };
}

export function fetchMessages(user: Object, username: String) {
  return {
    types: [GET_MESSAGES, GET_MESSAGES_SUCCESS, GET_MESSAGES_FAIL],
    user,
    username,
    promise: (client: Object) => client.get(`${api.host}/inbox/messages?limit=0`),
  };
}

export function fetchMessageById(id: number) {
  return {
    types: [GET_MESSAGE_BY_ID, GET_MESSAGE_BY_ID_SUCCESS, GET_MESSAGE_BY_ID_FAIL],
    promise: (client: Object) => client.get(`${api.host}/inbox/message/${id}`),
  };
}

export function openMessageDetails(id: number) {
  return (dispatch: Function) => {
    dispatch(fetchMessageById(id)); // mark as read
    dispatch({
      type: VIEW_MESSAGE,
      id,
    });
    dispatch({
      type: TOGGLE_MESSAGE_DETAILS,
      id,
    });
  };
}

export function openMessageReply(id: number) {
  return {
    type: OPEN_MESSAGE_REPLY,
    id,
  };
}

export function onEditMailReply(id: number, field: string, value: string) {
  return {
    type: EDIT_MAIL_REPLY,
    id,
    field,
    value,
  };
}

export function cancelReply(id: number) {
  return {
    type: CANCEL_MAIL_REPLY,
    id,
  };
}

export function onSendEmail(id: number, message: { text: string, subject: string }, to: string) {
  return {
    types: [SEND_REPLY, SEND_REPLY_SUCCESS, SEND_REPLY_FAIL],
    id,
    promise: (client: Object) =>
      client.post(`${api.host}/inbox/message/reply`, {
        data: {
          to,
          text: message.text,
          subject: message.subject,
        },
      }),
  };
}

export function clearSuccessNotification(id: number) {
  return {
    type: CLEAR_SUCCESS_NOTIFICATION,
    id,
  };
}

injectAsyncReducers({ mail: reducer });
