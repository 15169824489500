import { MfeComponent } from 'components/MfeComponent';
import { unifiedProfileMfeUrl } from 'config/api';
import React from 'react';

export const UnifiedProfileRoute = () => {
  return (
    <MfeComponent
      url={unifiedProfileMfeUrl}
      module="./UnifiedProfileMfe"
      remote="ps_mfe_naviance_unified_profile"
      moduleType="module"
    />
  );
};
